














import Vue from 'vue'

import TheMainNavCatalogDesktop from './TheMainNavCatalogDesktop.vue'
import TheMainNavCatalogMobile from './TheMainNavCatalogMobile.vue'

export default Vue.extend({
    components: {
        TheMainNavCatalogDesktop,
        TheMainNavCatalogMobile
    },

    props: {
        value: Boolean
    },

    methods: {
        onInput(event: boolean): void {
            this.$emit('input', event)
        },
    }
})
