



















import Vue from 'vue'
import Oauth from '@/oauth'
import Routes from "@/constants/routes"
import Roles from "@/constants/roles"
import {
    HUserMenu,
    HMenuItem
} from "@happytal/bo-ui-library"

export default Vue.extend({
    components: {
        HUserMenu,
        HMenuItem
    },

    computed: {
        activeUser(): Record<string, any> {
            return this.$store.state.dataUser.user
        },
        roleUserText(): string {
            switch (this.activeUser.displayRole) {
                case Roles.EhpadEmployee:
                    return "Collaborateur EHPAD"
                case Roles.EhpadManager:
                    return "Manager EHPAD"
                case Roles.Family:
                    return "Famille"
                case Roles.Guest:
                    return "Invité"
                case Roles.Tutor:
                    return "Tuteur"
                case Roles.HappytalProvider:
                    return "Partenaire happytal"
                case Roles.Provider:
                    return "Partenaire"
                case Roles.HappytalEmployee:
                    return "Collaborateur happytal"
                case Roles.HappytalSupport:
                    return "Support happytal"
                default:
                    return ""
            }
        }
    },

    methods: {
        onAccountBtnClick(e: any): void {
            this.$router.push({
                name: Routes.UserProfile
            })
        },
        onLogoutClick(e: any): void {
            Oauth.logout().then((logoutUrl: string) => {
                window.location.href = logoutUrl
            })
        }
    }
})
