





























































































import Vue from 'vue'
import mixins from 'vue-typed-mixins'
import appsettings from '@/appsettings'

import Routes from '@/constants/routes'
import residentMixin from '@/mixins/residentMixin'

import {
    HMainNav,
    HBadge,
    HBtn,
    HIcon,
    HList,
    HListItemGroup,
    HMenuItem,
    HMainNavMenuItem,
    MenuItem
} from '@happytal/bo-ui-library'
import TheOverlaySubmenu from './TheOverlaySubmenu.vue'
import { CatalogMenuItem } from '@/types/components'

const featuredCategoryId = appsettings.VUE_APP_FEATURED_CATEGORY_ID

let timeoutForMouseOverItemMenu: any = null
let timeoutForMouseOverNav: any = null

export default mixins(residentMixin).extend({
    components: {
        HMainNav,
        HBadge,
        HBtn,
        HIcon,
        TheOverlaySubmenu,
        HList,
        HListItemGroup,
        HMenuItem,
        HMainNavMenuItem
    },

    props: {
        value: Boolean
    },

    data () {
        return {
            navWidth: 270,
            activeMenuItemOvered: null as CatalogMenuItem | null,
            featuredCategoryId
        }
    },

    computed: {
        // Main nav computed
        catalogItemMenu (): MenuItem {
            return {
                title: 'Catalogue',
                icon: 'BuildingStoreIcon',
                route: {
                    name: Routes.ResidentCatalog
                },
            }
        },
        menuItems (): CatalogMenuItem[] {
            return this.$store.getters['dataCatalogCategories/getCategoriesForMainNav'](false)
        },

        //Submenu computed
        overlaySubmenuActive (): boolean {
            return Boolean(this.activeMenuItemOvered)
        },
        isActiveSubitemsGroupedList (): boolean {
            return Boolean(this.activeMenuItemOvered?.subItems.find(subItem => subItem.subItems.length > 0))
        },
        isActiveSubitemsSimpleList (): boolean {
            return Boolean(!this.isActiveSubitemsGroupedList && this.activeMenuItemOvered?.subItems.length)
        }
    },

    mounted () {
        const nav = (this.$refs.navCatalog as Vue).$el as HTMLElement
        if (nav.offsetWidth) {
            this.navWidth = nav.offsetWidth
        }
        nav.addEventListener('mouseover', this.onMouseOverMainNav)
        nav.addEventListener('mouseout', this.onMouseOutMainNav)

        // Avoid to keep default nav by overring
        this.$store.dispatch('dataMainNav/updateOverrideNavByDefault', false)
    },
    beforeDestroy () {
        const nav = (this.$refs.navCatalog as Vue).$el as HTMLElement
        nav.removeEventListener('mouseover', this.onMouseOverMainNav)
        nav.removeEventListener('mouseout', this.onMouseOutMainNav)
    },

    methods: {
        onInputMainNav (event: boolean): void {
            this.$emit('input', event)
        },
        onMouseEnterItemMenu (item?: CatalogMenuItem): void {
            timeoutForMouseOverItemMenu = setTimeout(() => {
                if (item?.subItems?.length) {
                    this.displaySubmenu(item)
                }
                else if (this.overlaySubmenuActive) {
                    this.hideSubmenu()
                }
            }, 100)
        },
        onMouseLeaveItemMenu (): void {
            clearTimeout(timeoutForMouseOverItemMenu)
        },
        onMouseOverMainNav (): void {
            if (this.overlaySubmenuActive) {
                timeoutForMouseOverNav = setTimeout(() => {
                        this.hideSubmenu()
                    },
                    100
                )
            }
        },
        onMouseOutMainNav (): void {
            clearTimeout(timeoutForMouseOverNav)
        },
        displaySubmenu (item: CatalogMenuItem): void {
           this.activeMenuItemOvered = item
        },
        hideSubmenu (): void {
            this.activeMenuItemOvered = null
        },
        backToDefaultNav (): void {
            this.$store.dispatch('dataMainNav/updateOverrideNavByDefault', true)
        }
    },
})
