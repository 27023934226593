

















import Vue from 'vue'
import Routes from '@/constants/routes'
import TheMainNavDefault from './TheMainNavDefault.vue'
import TheMainNavCatalog from './TheMainNavCatalog.vue'

export default Vue.extend({
    components: {
        TheMainNavDefault,
        TheMainNavCatalog
    },

    props: {
        value: Boolean
    },

    computed: {
        isCatalogPage(): boolean {
            return [
                Routes.ResidentCatalog,
                Routes.CatalogProduct,
                Routes.CatalogService,
                Routes.CatalogVariant,
                Routes.AlgoliaProductSearch
            ].includes(this.$route.name)
        },
        overrideNavByDefault(): boolean {
            return this.$store.state.dataMainNav.overrideNavByDefault
        },
        displayNavCatalog(): boolean {
            if (this.overrideNavByDefault) return false
            else return this.isCatalogPage
        }
    },

    methods: {
        onInput(event: boolean): void {
            this.$emit('input', event)
            if (!event && this.overrideNavByDefault) {
                this.$store.dispatch('dataMainNav/updateOverrideNavByDefault', false)
            }
        }
    }
})
