import { render, staticRenderFns } from "./TheMainNavCatalogDesktop.vue?vue&type=template&id=e9e25310&scoped=true&"
import script from "./TheMainNavCatalogDesktop.vue?vue&type=script&lang=ts&"
export * from "./TheMainNavCatalogDesktop.vue?vue&type=script&lang=ts&"
import style0 from "./TheMainNavCatalogDesktop.vue?vue&type=style&index=0&id=e9e25310&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9e25310",
  null
  
)

export default component.exports