



















import mixins from 'vue-typed-mixins'

import Http from '@/http'
import Routes from '@/constants/routes'

import institutionMixin from '@/mixins/institutionMixin'
import currentUserMixin from '@/mixins/currentUserMixin'

import TheMainNav from '@/components/TheMainNav.vue'
import TheMainNavCatalog from '@/components/TheMainNavCatalog.vue'
import TheHeaderBar from '@/components/navbar/TheHeaderBar.vue'
import ConsentPopin from '@/components/ConsentPopin.vue'
import TheFooter from '@/components/TheFooter.vue'
import { HMain } from '@happytal/bo-ui-library'

import { Location } from 'vue-router'

export default mixins(institutionMixin, currentUserMixin).extend({
    name: 'Main',

    components: {
        TheMainNav,
        TheMainNavCatalog,
        TheHeaderBar,
        HMain,
        ConsentPopin,
        TheFooter
    },

    data() {
        return {
            mainNav: this.$breakpoint.lgAndUp,
            canDisplayRouterView: false,
            childRouteLoaded: !this.$route?.meta?.waitRouteLoaded
        }
    },

    computed: {
        displayPageTitle(): boolean {
            return Boolean(this.$route.meta?.displayPageTitle && this.$route.meta?.pageTitle)
        },
        pageTitle(): string {
            const pageTitle = this.$route.meta?.pageTitle

            switch (typeof pageTitle) {
                case 'string':
                    return pageTitle
                case 'function':
                    return pageTitle(this.$route)
                default:
                    return ''
            }
        },
        shouldWaitRouteLoaded(): boolean {
            return this.$route?.meta?.waitRouteLoaded
        },
        canCloseLoader(): boolean {
            return !this.shouldWaitRouteLoaded || (this.shouldWaitRouteLoaded && this.childRouteLoaded)
        },
    },

    watch: {
        childRouteLoaded(newValue: boolean, oldValue: boolean) {
            if (newValue !== oldValue) {
                if (newValue) this.closeLoader()
                else this.openLoader()
            }
        },
        '$store.state.dataMainNav.overrideNavDisplay'(val: boolean) {
            if(val) {
                this.mainNav = true
                this.$store.dispatch('dataMainNav/updateOverrideNavDisplay', false)
            }
        }
    },

    async mounted() {
        const path = this.$route.path
        const isResidentPage = path.match(/residents\/([0-9a-f-]+)\//i)
        const residentId = this.$route.params?.resident_id
        const isResidentsRegistered = this.$store.state.dataResidents.isRegistered
        const isOrdersRegistered = this.$store.state.dataOrders.isRegistered
        const isBasketsRegistered = this.$store.state.dataBaskets.isRegistered

        this.openLoader()

        if (isResidentPage && residentId) {
            const getResident = await Http.apis.ehpad.get(`/residents/${residentId}`)
            const resident = getResident.data.resident

            //Check if we have to update current resident id in store
            if (this.$store.state.dataResidents.currentResidentId !== residentId) {
                this.$store.dispatch('dataResidents/updateCurrentResidentId', residentId)
            }

            //Check if user has same institutionId as resident
            if (resident.institutionId !== this.$_currentInstitutionId) {
                await this.$store.dispatch('dataUser/updateInstitution', {
                    institutionId: resident.institutionId
                })
                window.location.href = path
                return
            }
        }

        try {
            //Get residents if it isn't registered
            if (!isResidentsRegistered) {
                await this.$store.dispatch('dataResidents/getResidents', {
                    userId: this.$_currentUserId,
                    institutionId: this.$_currentInstitutionId
                })
            }

            this.canDisplayRouterView = true
            if (this.canCloseLoader) {
                this.closeLoader()
            }

            //Get orders if it isn't registered
            if (!isOrdersRegistered) {
                await this.$store.dispatch('dataOrders/getCurrentOrders', {
                    userId: this.$_currentUserId,
                    institutionId: this.$_currentInstitutionId
                })
            }

            //Get baskets if it isn't registered
            if (!isBasketsRegistered) {
                await this.$store.dispatch('dataBaskets/getUserBaskets', {
                    userId: this.$_currentUserId,
                    institutionId: this.$_currentInstitutionId
                })
            }


            //Push analytic login
            if (this.$gtm.enabled()) {
                this.$gtm.trackEvent({
                    event: 'login',
                    role: this.$_currentUserRole,
                    userId: this.$_currentUserId,
                    institutionId: this.$_currentInstitutionId
                })
            }
        } catch (error) {
            if (this.canCloseLoader) {
                this.closeLoader()
            }
            console.error(error)
        }
    },

    methods: {
        getRouterViewKey(route: Location): string {
            if (route.name === Routes.CatalogVariant) {
                return `/residents/${route?.params?.resident_id}/variant/${route?.params?.variant_id}`
            }
            else return route.path as string
        },
        toggleMainNav(): void {
            this.mainNav = !this.mainNav
        },
        onInputMainNav(value: boolean): void {
            this.mainNav = value
        },
        openLoader(): void {
            this.$vs.loading()
        },
        closeLoader(): void {
            const sto = setTimeout(() => {
                this.$vs.loading.close()
                clearTimeout(sto)
            }, 1)
        },
        onLoadRoute(value: boolean) {
            this.childRouteLoaded = value

            if (value) {
                this.closeLoader()
            }
        }
    }
})
