























import Vue from 'vue'

import { HTopBar, HBtn } from '@happytal/bo-ui-library'

import BtnHelpCallback from '@/components/BtnHelpCallback.vue'
import InstitutionMenu from '@/components/navbar/InstitutionMenu.vue'
import HelpCenterBtn from '@/components/navbar/HelpCenterBtn.vue'
import UserMenu from '@/components/navbar/UserMenu.vue'

export default Vue.extend({
    components: {
        HTopBar,
        HBtn,
        InstitutionMenu,
        HelpCenterBtn,
        BtnHelpCallback,
        UserMenu
    },
})
