


























































import Vue from 'vue'

import { ToastObject } from 'vue-toasted/types/index'
import { Institution } from '@/types/index'

import { HMenu, HMenuSearch, HMenuItem, HPopin, HBtn } from '@happytal/bo-ui-library'

export default Vue.extend({
    components: {
        HMenu,
        HMenuSearch,
        HMenuItem,
        HPopin,
        HBtn
    },

    data () {
        return {
            menuSearchValue: "",
            institutionSelected: null as any,
            popinModel: false
        }
    },

    computed: {
        currentInstitutionId(): number | null {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
        currentInstitution(): Institution | null {
            return this.currentInstitutionId ? this.getInstitutionById(this.currentInstitutionId) : null
        },
        currentInstitutionName(): string {
            return this.currentInstitution ? this.currentInstitution.name : '-'
        },
        currentInstitutionCity(): string {
            return this.currentInstitution ? this.currentInstitution.address.city : '-'
        },
        institutions(): Institution[] {
            const institutionIds = this.getUserInstitutionIds()
            const institutions = institutionIds.map((id: number) => {
                return this.getInstitutionById(id)
            })
            return institutions.filter((institution: Institution) => {
                return institution && institution.id != this.currentInstitutionId
            })
        },
        filteredInstitutions(): Institution[] {
            if (this.institutions.length) {
                return this.institutions.filter( item => {
                    return item.name.toLowerCase().includes(this.menuSearchValue.toLowerCase())
                })
            }
            return []
        },
        isMultipleInstitutions(): boolean {
            return this.getUserInstitutionIds().length > 1
        },
    },

    methods: {
        getUserInstitutionIds(): number[] {
            return this.$store.getters['dataUser/getUserInstitutionIds']()
        },
        getInstitutionById(institutionId: number): Institution {
            return this.$store.getters['dataInstitutions/getInstitutionById'](institutionId)
        },
        onClickInstitution (institution: Institution) {
            this.institutionSelected = institution
            this.popinModel = true
        },
        async changeInstitution () {
            try {
                await this.$store.dispatch('dataUser/updateInstitution', {
                    institutionId: this.institutionSelected.id
                })
                this.popinModel = false
                if (this.$gtm.enabled()) {
                    this.$gtm.trackEvent({
                        event: 'change_institution',
                        institutionId: this.institutionSelected.id
                })
    }
                window.location.href = '/residents'
            } catch (error) {
                this.popinModel = false
                this.showErrorToast(`Une erreur s'est produite (${error}).`)
            }
        },
        showErrorToast(text: string) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration : null,
                action : [
                    {
                        text : 'Fermer',
                        onClick: (e: Event, to: ToastObject) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        },
        clearMenuSearchValue(value: boolean): void {
            if (!value) {
                const sto = setTimeout( () => {
                    this.menuSearchValue = ""
                    clearTimeout(sto)
                }, 250)
            }
        }
    },

    mounted () {
        if (!this.$store.state.dataInstitutions.institutions.length) {
            this.$store.dispatch('dataInstitutions/getInstitutions')
        }
    }
})
