























import _ from 'lodash'
import { Location } from 'vue-router'
import mixins from 'vue-typed-mixins'

import Roles from '@/constants/roles'
import Routes from '@/constants/routes'
import residentMixin from '@/mixins/residentMixin'
import { getCurrentMonth, getNextMonth, isChristmasPeriod } from '@/modules/date'

import { HMainNav, HBadge } from '@happytal/bo-ui-library'
import { GroupItem, MenuItem } from '@happytal/bo-ui-library'

interface CustomMenuITem extends MenuItem {
    class?: string
}

export default mixins(residentMixin).extend({
    components: {
        HMainNav,
        HBadge
    },

    props: {
        value: Boolean
    },

    data(){
        return {
            displayChristmasLogo: isChristmasPeriod()
        }
    },

    computed: {
        headerLink(): Location {
            return {
                name: Routes.Home
            }
        },
        userRole(): string {
            return _.get(this.$store, 'state.dataUser.user.role', '')
        },
        residentBaseLink(): string {
            return this.$_residentId ? `/residents/${this.$_residentId}` : ''
        },
        openedSuggestionCount(): number {
            const self: any = this
            return self.$_resident ? self.$_resident.openedSuggestionCount : 0
        },
        totalOrderCurrent(): number {
            return _.get(this.$store, 'state.dataOrders.orders.length', 0)
        },
        totalOrderNext(): number {
            return _.get(this.$store, 'state.dataBaskets.user.length', 0)
        },
        numberOfResidents(): number {
            return _.get(this.$store, 'state.dataResidents.residents.length', 0)
        },
        isCatalogPage(): boolean {
            return [Routes.ResidentCatalog, Routes.CatalogProduct, Routes.CatalogService, Routes.CatalogVariant].includes(this.$route.name)
        },
        groupItems(): GroupItem[] {
            const self: any = this
            const groupItems: GroupItem[] = []

            if (this.numberOfResidents > 1) {
                groupItems.push({
                    id: 'top'
                })
            }

            if (this.$_residentId) {
                groupItems.push({
                    id: 'resident',
                    label: self.$_residentName as string
                })
            }

            if ( [Roles.HappytalSupport, Roles.HappytalEmployee, Roles.Family, Roles.EhpadManager].includes(this.userRole) ) {
                groupItems.push({
                    id: 'family',
                    label: `Mon rôle d'aidant`
                })
            }

            if ( [Roles.HappytalSupport, Roles.HappytalEmployee].includes(this.userRole) ) {
                groupItems.push({
                    id: 'orderSubscription',
                    label: 'Suivi des abonnements'
                })
            }

            if ( [Roles.HappytalSupport, Roles.HappytalEmployee, Roles.EhpadManager, Roles.EhpadEmployee].includes(this.userRole) ) {
                groupItems.push({
                    id: 'orderTracking',
                    label: 'Suivi des commandes'
                })
            }

            if ( [Roles.HappytalSupport, Roles.HappytalEmployee, Roles.EhpadManager, Roles.EhpadEmployee].includes(this.userRole) ) {
                groupItems.push({
                    id: 'suggestionTracking',
                    label: 'Suivi des suggestions'
                })
            }

            return groupItems
        },
        menuItems(): CustomMenuITem[] {
            const menuItems: CustomMenuITem[] = []

            //Liste des résidents
            if(this.numberOfResidents > 1) {
                menuItems.push({
                    title: 'Liste des résidents',
                    route: { name:  Routes.Residents },
                    groupId: 'top',
                    icon: 'UsersIcon',
                    exactPath: true,
                })
            }

            //Menu résident
            if (this.$_residentId) {
                menuItems.push(
                    {
                        title: 'Accueil',
                        route: {
                            name: Routes.ResidentDashboard,
                            params: {
                                resident_id: this.$_residentId
                            }
                        },
                        groupId: 'resident',
                        icon: 'SmartHomeIcon',
                    },
                    {
                        title: 'Suggestions',
                        route: {
                            name: Routes.ResidentSuggestions,
                            params: {
                                resident_id: this.$_residentId
                            }
                        },
                        groupId: 'resident',
                        icon: 'MessageIcon',
                        badge: this.openedSuggestionCount ? `${this.openedSuggestionCount}` : undefined
                    },
                    {
                        title: 'Catalogue',
                        route: {
                            name: Routes.ResidentCatalog,
                            params: {
                                resident_id: this.$_residentId
                            }
                        },
                        groupId: 'resident',
                        icon: 'BuildingStoreIcon',
                        class: this.isCatalogPage ? 'v-list-item--active primary' : ''
                    },
                    {
                        title: 'Services complémentaires',
                        route: {
                            name: Routes.ExtraServices,
                            params: {
                                resident_id: this.$_residentId
                            }
                        },
                        groupId: 'resident',
                        icon: 'LayoutGridIcon',
                    },
                    {
                        title: 'Commandes',
                        route: {
                            name: Routes.ResidentHistory,
                            params: {
                                resident_id: this.$_residentId
                            }
                        },
                        groupId: 'resident',
                        icon: 'ArchiveIcon',
                    },
                    {
                        title: 'Abonnements',
                        route: {
                            name: Routes.ResidentSubscriptionsCurrent,
                            params: {
                                resident_id: this.$_residentId
                            }
                        },
                        groupId: 'resident',
                        icon: 'ClipboardListIcon',
                    },
                    {
                        title: 'Profil',
                        route: {
                            name: Routes.ResidentProfile,
                            params: {
                                resident_id: this.$_residentId
                            }
                        },
                        groupId: 'resident',
                        icon: 'UserIcon',
                    },
                )
            }

            //Mes questions
            if ( [Roles.HappytalSupport, Roles.HappytalEmployee, Roles.Family, Roles.EhpadManager].includes(this.userRole) ) {
                menuItems.push({
                    title: 'Mes questions',
                    route: this.$_residentId ? ({
                        name: Routes.ResidentHelpCategory,
                        params: {
                            resident_id: this.$_residentId
                        }
                     }) : ({
                        name: Routes.HelpCategory
                     }),
                    groupId: 'family',
                    icon: 'NotebookIcon',
                })
            }

            //Suivi des commandes
            if ([Roles.HappytalSupport, Roles.HappytalEmployee].includes(this.userRole)) {
                menuItems.push(
                    {
                        title: getCurrentMonth(),
                        route: { name:  Routes.Orders, params: { status: 'en-livraison' } },
                        groupId: 'orderSubscription',
                        icon: 'BasketIcon',
                        badge: `${this.totalOrderCurrent}`
                    },
                    {
                        title: getNextMonth(),
                        route: { name:  Routes.Orders, params: { status: 'en-cours' } },
                        groupId: 'orderSubscription',
                        icon: 'BasketIcon',
                        badge: `${this.totalOrderNext}`
                    },
                )
            }

            //Suivi des commandes
            if ( [Roles.HappytalSupport, Roles.HappytalEmployee, Roles.EhpadManager, Roles.EhpadEmployee].includes(this.userRole) ) {
                menuItems.push({
                    title: 'Livraisons',
                    route: { name:  Routes.LogisticShipping },
                    groupId: 'orderTracking',
                    icon: 'TruckDeliveryIcon',
                })
                menuItems.push({
                    title: 'Rendez-vous',
                    route: { name:  Routes.LogisticAppointments },
                    groupId: 'orderTracking',
                    icon: 'CalendarIcon',
                })
            }

            //Suivi des suggestions
            if ( [Roles.HappytalSupport, Roles.HappytalEmployee, Roles.EhpadManager, Roles.EhpadEmployee].includes(this.userRole) ) {
                menuItems.push({
                    title: 'Suggestions',
                    route: { name:  Routes.SuggestionDashboard },
                    groupId: 'suggestionTracking',
                    icon: 'MessageIcon',
                })
            }

            return menuItems
        }
    },

    methods: {
        onInput(event: boolean): void {
            this.$emit('input', event)
        },
        onClickMenuItem(menuItem: MenuItem): void {
            if (typeof menuItem?.route === 'object' && menuItem?.route?.name === Routes.ResidentCatalog) {
                this.$store.dispatch('dataMainNav/updateOverrideNavByDefault', false)
            }
        }
    },
})
