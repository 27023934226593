

















import Vue from 'vue'
export default Vue.extend({
    props: {
        active: Boolean,
        zIndex: {
            type: Number,
            default: 6
        },
        cursorPointer: Boolean
    },

    methods: {
        emitScrimClick(): void {
            this.$emit('click:overlay')
        },
        emitScrimMouseOver(): void {
            this.$emit('mouseover:overlay')
        }
    }
})
