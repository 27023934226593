


















import Vue, { PropType } from 'vue'

import Overlay from '@/components/Overlay.vue'

export default Vue.extend({
    components: {
        Overlay
    },

    props: {
        active: Boolean,
        leftOffset: {
            type:[Number, String] as PropType<number | string>,
            default: 260
        },
        minWidth: {
            type: [Number, String] as PropType<number | string>
        }
    },

    computed: {
        minWidthComputed(): string | undefined {
            if (!this.minWidth) return undefined
            if (typeof this.minWidth === 'number') return `${this.minWidth}px`
            return this.minWidth
        }
    },

    methods: {
        hideSubmenu(): void {
            this.$emit('hide')
        }
    }
})
