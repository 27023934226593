















import Vue from 'vue'

import Routes from '@/constants/routes'

import { HBtn } from "@happytal/bo-ui-library"

export default Vue.extend({
    components: {
        HBtn
    },

    computed: {
        toLink(): { name: string } {
            return {
                name: Routes.HelpCenter
            }
        },
        btnLabel(): string {
          return this.$breakpoint.smAndDown ? '' : 'Aide'
        }
    }
})
