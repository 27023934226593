















import mixins from 'vue-typed-mixins'

import currentUserMixin from '@/mixins/currentUserMixin'

import { HPopin, HBtn } from '@happytal/bo-ui-library'

export default mixins(currentUserMixin).extend({
    components: {
        HPopin,
        HBtn
    },

    data() {
        return {
            popinModel: true
        }
    },

    computed: {
        canUserSeePopin(): boolean {
            if(this.$_currentUser) {
                return this.$_currentUser.acceptCommunications === null
            }
            return false
        },
    },

    methods: {
        saveConsentChoice(consent: boolean): void {
            this.$store.dispatch('dataUser/updateConsent', {
                acceptCommunications: consent
            })
            this.popinModel = false
        },
    }
})
