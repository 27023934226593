



























































import mixins from 'vue-typed-mixins'
import appsettings from '@/appsettings'

import Routes from '@/constants/routes'
import residentMixin from '@/mixins/residentMixin'

import { HMainNav, HIcon, HListItemGroup, HMainNavMenuItem } from '@happytal/bo-ui-library'
import SeparatorLine from '@/components/SeparatorLine.vue'
import { MenuItem } from '@happytal/bo-ui-library/src/types'
import { CatalogMenuItem } from '@/types/components'

const featuredCategoryId = appsettings.VUE_APP_FEATURED_CATEGORY_ID

export default mixins(residentMixin).extend({
    components: {
        HMainNav,
        SeparatorLine,
        HIcon,
        HListItemGroup,
        HMainNavMenuItem
    },

    props: {
        value: Boolean
    },

    data(){
        return {
            levelMenu: 0,
            activeItemMenuWithSubItems: null as CatalogMenuItem | null,
            previousActiveItemMenuWithSubItems: null as CatalogMenuItem | null,
            featuredCategoryId
        }
    },

    computed: {
        catalogItemMenu(): MenuItem {
            return {
                title: 'Catalogue',
                icon: 'BuildingStoreIcon',
                route: {
                    name: Routes.ResidentCatalog,
                    params: {
                        resident_id: this.$_residentId
                    }
                },
            }
        },
        catalogCategories(): CatalogMenuItem[] {
            return this.$store.getters['dataCatalogCategories/getCategoriesForMainNav'](true)
        },
        menuItems(): CatalogMenuItem[] {
            if (this.levelMenu === 0) {
                return this.catalogCategories
            }
            return this.activeItemMenuWithSubItems?.subItems.length ? this.activeItemMenuWithSubItems.subItems : []
        },
    },

    mounted(){
        //Avoid to keep default nav by overring
        this.$store.dispatch('dataMainNav/updateOverrideNavByDefault', false)
    },

    methods: {
        toggleNav(event: boolean): void {
            this.$emit('input', event)
        },
        onClickMenuItem(item: CatalogMenuItem): void {
            if (item.route) {
                this.toggleNav(false)
            }
            else if (item.subItems.length) {
                this.levelMenu++
                this.previousActiveItemMenuWithSubItems = this.activeItemMenuWithSubItems ?? null
                this.activeItemMenuWithSubItems = item
            }
        },
        backToDefaultNav(): void {
            this.$store.dispatch('dataMainNav/updateOverrideNavByDefault', true)
        },
        backToPreviousCategory(): void {
            if (this.levelMenu === 1) {
                this.resetMenuState()
            }
            else {
                this.levelMenu--
                this.activeItemMenuWithSubItems = this.previousActiveItemMenuWithSubItems
                this.previousActiveItemMenuWithSubItems = null
            }
        },
        resetMenuState(): void {
            this.levelMenu = 0
            this.activeItemMenuWithSubItems = null
            this.previousActiveItemMenuWithSubItems = null
        }
    },
})
